import { ActivityLogResponse } from '@/models/activity-log';
import httpClient from './http-client';

export const ACTIVITY_LOG_ENDPOINT = 'activity-log';

export function getActivityLog(query: string): Promise<ActivityLogResponse[]> {
  return httpClient
    .get(`${ACTIVITY_LOG_ENDPOINT}?${query}`)
    .then((response) => response.data);
}

import { AxiosResponse } from 'axios';
import { AccountUUID } from '@ax/api-clients-accounts/models';
import {
  PaginatedResponse,
  GlobalPaginationMetadata,
} from '@/models/pagination';
import {
  Connection,
  ConnectionCreate,
  ConnectionUpdate,
  ConnectionUUID,
} from '@/models/authentication';
import { getBespokeAuthHttpClient } from '.';

const CONNECTIONS_ENDPOINT = '/connections';

// TODO stoplight URL
/**
 * Retrieves a single connection
 * @param accountId
 * @param connectionId
 * @return The connection.
 */
export function getConnection(
  accountId: AccountUUID,
  connectionId: ConnectionUUID,
): Promise<Connection> {
  return getBespokeAuthHttpClient()
    .get(`/accounts/${accountId}${CONNECTIONS_ENDPOINT}/${connectionId}`)
    .then(({ data }) => data);
}

// TODO stoplight URL
/**
 * Retrieves connections
 * @param accountId
 * @param params
 * @return The connections.
 */
export function getConnections(
  accountId: AccountUUID,
  params = {},
): Promise<PaginatedResponse<Connection, GlobalPaginationMetadata>> {
  return getBespokeAuthHttpClient()
    .get(`/accounts/${accountId}${CONNECTIONS_ENDPOINT}`, { params })
    .then(({ data }) => data);
}

// TODO stoplight URL
/**
 * Creates a connection
 * @param accountId
 * @param payload
 * @return The connection.
 */
export function createConnection(
  accountId: AccountUUID,
  payload: ConnectionCreate,
): Promise<Connection> {
  return getBespokeAuthHttpClient()
    .post(`/accounts/${accountId}${CONNECTIONS_ENDPOINT}`, payload)
    .then(({ data }) => data);
}

// TODO stoplight URL
/**
 * Updates a connection
 * @param accountId
 * @param connectionId
 * @param payload
 * @return The connection.
 */
export function updateConnection(
  accountId: AccountUUID,
  connectionId: ConnectionUUID,
  payload: ConnectionUpdate,
): Promise<AxiosResponse<Connection>> {
  return getBespokeAuthHttpClient().put(
    `/accounts/${accountId}${CONNECTIONS_ENDPOINT}/${connectionId}`,
    payload,
  );
}

// TODO stoplight URL
/**
 * Deletes a connection
 * @param accountId
 * @param connectionId
 * @return The connection delete response.
 */
export function deleteConnection(
  accountId: AccountUUID,
  connectionId: ConnectionUUID,
): Promise<AxiosResponse> {
  return getBespokeAuthHttpClient().delete(
    `/accounts/${accountId}${CONNECTIONS_ENDPOINT}/${connectionId}`,
  );
}

import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { AccountUUID } from '@ax/api-clients-accounts/models';
import {
  PaginatedResponse,
  GlobalPaginationMetadata,
} from '@/models/pagination';
import {
  Domain,
  DomainCreate,
  DomainUUID,
  DomainVerificationResponse,
} from '@/models/authentication';
import { getBespokeAuthHttpClient } from '.';

const DOMAINS_ENDPOINT = '/domains';

interface DomainQuery {
  status?: 'verified' | 'unverified';
  page?: number;
  limit?: number;
  sort?: string;
}

// TODO stoplight URL
/**
 * Retrieves a single domain
 * @param accountId
 * @param domainId
 * @return The domain.
 */
export function getDomain(
  accountId: AccountUUID,
  domainId: DomainUUID,
): Promise<Domain> {
  return getBespokeAuthHttpClient()
    .get(`/accounts/${accountId}${DOMAINS_ENDPOINT}/${domainId}`)
    .then(({ data }) => data);
}

// TODO stoplight URL
/**
 * Retrieves domains
 * @param accountId
 * @param params
 * @return The domains.
 */
export function getDomains(
  accountId: AccountUUID,
  params: DomainQuery = {},
): Promise<PaginatedResponse<Domain, GlobalPaginationMetadata>> {
  const { status, ...restParams } = params;
  return getBespokeAuthHttpClient()
    .get(`/accounts/${accountId}${DOMAINS_ENDPOINT}`, {
      params: {
        ...restParams,
        ...(status && { 'verified:eq': status === 'verified' }),
      },
    })
    .then(({ data }) => data);
}

// TODO stoplight URL
/**
 * Creates a domain
 * @param accountId
 * @param payload
 * @param config
 * @return The domain.
 */
export function createDomain(
  accountId: AccountUUID,
  payload: DomainCreate,
  config: AxiosRequestConfig = {},
): Promise<Domain> {
  return getBespokeAuthHttpClient()
    .post(`/accounts/${accountId}${DOMAINS_ENDPOINT}`, payload, config)
    .then(({ data }) => data);
}

// TODO stoplight URL
/**
 * Deletes a domain
 * @param accountId
 * @param domainId
 * @return The domain delete response.
 */
export function deleteDomain(
  accountId: AccountUUID,
  domainId: DomainUUID,
): Promise<AxiosResponse> {
  return getBespokeAuthHttpClient().delete(
    `/accounts/${accountId}${DOMAINS_ENDPOINT}/${domainId}`,
  );
}

/**
 * Verifies a domain
 * @param accountId
 * @param domainId
 * @return The domain.
 */
export function verifyDomain(
  accountId: AccountUUID,
  domainId: DomainUUID,
): Promise<DomainVerificationResponse> {
  return getBespokeAuthHttpClient()
    .post(`/accounts/${accountId}${DOMAINS_ENDPOINT}/${domainId}/verify`)
    .then(({ data }) => data);
}

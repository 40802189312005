import { AxiosResponse } from 'axios';
import { UserUUID } from '@ax/api-clients-accounts/models';
import { AuthenticatorEnrollmentTicket, Authenticator } from '@/models/mfa';
import { getBespokeAuthHttpClient } from '.';

const MFA_ENDPOINT = '/users/mfa';

export function createAuthenticatorEnrollmentTicket(): Promise<AuthenticatorEnrollmentTicket> {
  return getBespokeAuthHttpClient()
    .post(MFA_ENDPOINT)
    .then(({ data }) => data);
}

export function getAuthenticators(): Promise<Authenticator[]> {
  return getBespokeAuthHttpClient()
    .get(MFA_ENDPOINT)
    .then(({ data }) => data);
}

export function resetUserAuthenticators(
  userId: UserUUID,
): Promise<AxiosResponse> {
  return getBespokeAuthHttpClient().delete(`${MFA_ENDPOINT}/${userId}/reset`);
}

/* eslint-disable max-classes-per-file */

import { TableOptions } from '@/types/table-options';
import { PolicyDeviceFilter } from '@/models/policy-device-filter';
import { format12HourTime } from '@/utils/date-time';
import {
  BitwiseSchedulerTimes,
  DEFAULT_TIME,
} from '@/components/Scheduler/scheduler-models';

/**
 * @deprecated instead use `import { PolicyTypeName } from '@ax/data-services-policies/models'`;
 */
export enum PrimaryPolicyTypes {
  patch = 'patch',
  required_software = 'required_software',
  worklet = 'worklet',
  custom = 'custom',
}

export enum PolicyTypes {
  patch_all = 'Patch All Policy',
  patch_all_except = 'Patch All Except Policy',
  patch_only = 'Patch Only Policy',
  patch_manual_approval = 'Manual Approval Policy',
  patch_by_severity = 'By Severity Policy',
  patch_advanced = 'Advanced Policy',
  required_software_policy = 'Required Software Policy',
  worklet = 'Worklet',
  manual_approval = 'Manual Approval',
}

export enum PolicyTypeQueryParams {
  patch_all = 'patchAll',
  patch_all_except = 'patchAllExcept',
  patch_only = 'patchOnly',
  patch_manual_approval = 'manualApprove',
  patch_by_severity = 'bySeverity',
  patch_advanced = 'advanced',
}

export enum PolicyFilterTypes {
  all = 'all',
  exclude = 'exclude',
  include = 'include',
  severity = 'severity',
}

export enum PolicyPatchRule {
  advanced = 'advanced',
  all = 'all',
  filter = 'filter',
  manual = 'manual',
}

export enum PolicySeverity {
  critical = 'critical',
  high = 'high',
  medium = 'medium',
  low = 'low',
  none = 'none',
  other = 'other',
}

export enum PolicyAdvancedCondition {
  is = 'is',
  is_not = 'is-not',
  less_than_or_equal = 'less-than-or-equal-to',
  greater_than_or_equal = 'greater-than-or-equal-to',
  contains = 'contains',
  does_not_contain = 'does-not-contain',
}

export enum PolicyAdvancedLeft {
  patch_source = 'patch-source',
  patch_os = 'patch-os',
  type = 'type',
  display_name = 'display-name',
  severity = 'severity',
  patch_days_old = 'patch-days-old',
}

export enum PolicyAdvancedRight {
  windowsupdate = 'windowsupdate',
  microsoft = 'microsoft',
  apple = 'apple',
  oracle = 'oracle',
  mozilla = 'mozilla',
  adobe = 'adobe',
  opera = 'opera',
  windows = 'windows',
  mac = 'mac',
  linux = 'linux',
  application = 'application',
  connectors = 'connectors',
  critical_updates = 'critical-updates',
  definition_updates = 'definition-updates',
  developer_kits = 'developer-kits',
  feature_packs = 'feature-packs',
  guidance = 'guidance',
  security_updates = 'security-updates',
  service_packs = 'service-packs',
  tools = 'tools',
  update_rollups = 'update-rollups',
  updates = 'updates',
  upgrades = 'upgrades',
  other = 'other',
  none = 'none',
  low = 'low',
  medium = 'medium',
  high = 'high',
  critical = 'critical',
}

export class PolicyAdvancedFilter {
  condition?: PolicyAdvancedCondition | null;

  left?: PolicyAdvancedLeft | null;

  right?: PolicyAdvancedRight | null;

  constructor(init: Partial<PolicyAdvancedFilter>) {
    Object.assign(this, init);
  }
}

export interface PolicyApprovalTableOptions extends TableOptions {
  status?: string[];
  osFamily?: string[];
  displayName: string;
  metaData: Record<keyof PolicyApprovalTableOptions, { maxLength: number }>;
  pid: number[];
  o: number;
  includeOptional: boolean;
  limit: number;
  page: number;
}

export class PolicyConfiguration {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  filters?: any[];

  os_family?: string;

  remediation_code?: string = '';

  evaluation_code?: string = '';

  installation_code?: string = '';

  auto_patch?: boolean;

  patch_rule?: PolicyPatchRule;

  filter_type?: PolicyFilterTypes;

  advanced_filter?: PolicyAdvancedFilter[];

  severity_filter?: PolicySeverity[];

  include_optional?: boolean;

  missed_patch_window?: boolean;

  package_name?: string;

  package_version?: string;

  use_scheduled_timezone?: boolean;

  scheduled_timezone?: string;

  refresh_before_remediation?: boolean = false;

  /* Reboot Notification Values - Start */
  auto_reboot?: boolean;

  notify_user?: boolean;

  notify_reboot_user?: boolean;

  notify_deferred_reboot_user?: boolean;

  custom_notification_patch_message?: string;

  custom_notification_patch_message_mac?: string;

  custom_notification_reboot_message?: string;

  custom_notification_reboot_message_mac?: string;

  custom_notification_deferment_periods?: number[];

  custom_notification_max_delays?: number | null;

  custom_pending_reboot_notification_message?: string;

  custom_pending_reboot_notification_message_mac?: string;

  custom_pending_reboot_notification_deferment_periods?: number[];

  custom_pending_reboot_notification_max_delays?: number | null;

  notify_deferred_reboot_user_auto_deferral_enabled?: boolean;

  notify_deferred_reboot_user_message_timeout?: number;

  notify_user_message_timeout?: number;

  notify_user_auto_deferral_enabled?: boolean;
  /* Reboot Notification Values - End */

  /* Non-API variables for UI/Form - Start */
  install_deferral_enabled?: boolean;

  pending_reboot_deferral_enabled?: boolean;
  /* Non-API variables for UI/Form - End */

  device_filters_enabled?: boolean;

  device_filters?: PolicyDeviceFilter[];

  constructor(init: Partial<PolicyConfiguration>) {
    Object.assign(this, init);
  }
}

export class PolicySchedule {
  date?: string;

  timezone_type?: number;

  timezone?: string;

  constructor(init: Partial<PolicySchedule>) {
    Object.assign(this, init);
  }
}

export class Policy implements BitwiseSchedulerTimes {
  id?: number;

  name?: string = '';

  policy_type_name?: string = '';

  organization_id?: number;

  configuration?: PolicyConfiguration;

  schedule_days = 0;

  schedule_weeks_of_month = 0;

  schedule_months = 0;

  schedule_time = format12HourTime(DEFAULT_TIME);

  notes?: string = '';

  create_time?: string = '';

  next_remediation?: string = undefined;

  server_groups: number[] = [];

  server_group_count?: number = undefined;

  server_count?: number = undefined;

  status?: number;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  result?: any;

  constructor(init: Partial<Policy>) {
    this.configuration = new PolicyConfiguration({});
    Object.assign(this, init);
    this.server_groups = this.server_groups || [];
  }
}

export class PolicyPackage {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  approvals?: any;

  display_name?: string;

  id?: number;

  name?: string;

  os_family?: string;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  patch_classification_category_id?: any;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  patch_scope?: any;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  pending_update?: any;

  repo?: string;

  server_count?: number;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  severity?: any;

  software_id?: number;

  total_count?: number;

  total_server_count?: number;

  total_severity_critical_count?: number;

  total_severity_high_count?: number;

  total_severity_low_count?: number;

  total_severity_medium_count?: number;

  total_severity_none_count?: number;

  total_severity_other_count?: number;

  version?: string;

  constructor(init: Partial<PolicyPackage>) {
    Object.assign(this, init);
  }
}

export class PolicyPreview {
  results?: PolicyPackage[];

  size?: number;

  constructor(init: Partial<PolicyPreview>) {
    Object.assign(this, init);
  }
}

import { UnknownRecord } from '@ax/object-utils';
import { JSONSchemaType } from 'vue-form-json-schema/dist/vue-form-json-schema.esm';

export enum ActionType {
  AgentDeployment = 'agent-deployment',
  AutomatedVulnerabilityRemediation = 'automated-vulnerability-remediation',
}

export interface IntegrationConnection {
  id: string;
  name: string;
  action_id: string;
  fields: UnknownRecord;
  created_at?: string;
  updated_at?: string;
}

export interface IntegrationConfiguration {
  id: string;
  name: string;
  enabled: boolean;
  connection_id: string;
  fields: UnknownRecord;
  created_at?: string;
  updated_at?: string;
}

export interface IntegrationAction {
  author: string;
  id: string;
  name: string;
  schema: JSONSchemaType;
  type: ActionType;
  version: string;
}

export interface ActionConnectionRequestBody {
  action_id: string;
  connection: UnknownRecord;
  name: string;
}

export interface ActionConfigurationRequestBody {
  connection_id: string;
  input: UnknownRecord;
  name: string;
}

export interface ActionExecutionRequestBody {
  configuration_id?: string;
}

export enum ActionExecutionStatus {
  // TODO: Decide if created and queued are the same and remove one if so.
  Created = 'created',
  Queued = 'queued',
  Running = 'running',
  Completed = 'completed',
  Error = 'error',
  Timeout = 'timeout',
}

export interface ActionExecutionOutput {
  [key: string]: number | string | UnknownRecord | undefined | null;
  summary?: string;
}

export interface ActionExecution {
  id: string;
  action_id: string;
  configuration_id: string;
  created_at: string;
  started_at?: string;
  ended_at?: string;
  status: ActionExecutionStatus;
  error_code?: string;
  error_message?: string;
  output?: ActionExecutionOutput;
}

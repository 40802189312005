import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { UnknownRecord } from '@ax/object-utils';
import { stringifyUrlQuery } from '@/utils/util';
import {
  ActionExecution,
  ActionExecutionRequestBody,
  ActionConfigurationRequestBody,
  ActionConnectionRequestBody,
  ActionType,
  IntegrationAction,
  IntegrationConnection,
  IntegrationConfiguration,
} from '@/models/integration-actions';
import {
  CursorPaginationMetadata,
  PaginatedResponse,
} from '@/models/pagination';
import httpClient from './http-client';

const INTEGRATION_ENDPOINT = '/integration';
const INTEGRATION_ACTION_ENDPOINT = `${INTEGRATION_ENDPOINT}/action`;
const INTEGRATION_EXECUTION_ENDPOINT = `${INTEGRATION_ENDPOINT}/execution`;
const INTEGRATION_CONNECTION_ENDPOINT = `${INTEGRATION_ENDPOINT}/connection`;
const INTEGRATION_CONFIGURATION_ENDPOINT = `${INTEGRATION_ENDPOINT}/configuration`;

/**
 * Makes sure that we have an error object in the format other UI code expects.
 */
function standardizeErrorResponse(error: AxiosResponse): never {
  if (error.data.error) {
    error.data.errors = [error.data.error];
  }
  throw error;
}

export function getConnections(
  orgId: string | number,
  showLoader = true,
): Promise<IntegrationConnection[]> {
  return httpClient
    .get<PaginatedResponse<IntegrationConnection, CursorPaginationMetadata>>(
      `${INTEGRATION_CONNECTION_ENDPOINT}?${stringifyUrlQuery({
        o: orgId,
      })}`,
      {
        loaderEnabled: showLoader,
      } as AxiosRequestConfig,
    )
    .then(({ data }) => data.data)
    .catch(standardizeErrorResponse);
}

export function getConfigurations(
  orgId: string | number,
  showLoader = true,
): Promise<IntegrationConfiguration[]> {
  return httpClient
    .get<PaginatedResponse<IntegrationConfiguration, CursorPaginationMetadata>>(
      `${INTEGRATION_CONFIGURATION_ENDPOINT}?${stringifyUrlQuery({
        o: orgId,
      })}`,
      {
        loaderEnabled: showLoader,
      } as AxiosRequestConfig,
    )
    .then(({ data }) => data.data)
    .catch(standardizeErrorResponse);
}

export function patchConfiguration(
  configurationId: string,
  orgId: string | number,
  payload: Partial<IntegrationConfiguration>,
  showLoader = true,
): Promise<never> {
  return httpClient
    .patch<never>(
      `${INTEGRATION_CONFIGURATION_ENDPOINT}/${configurationId}?${stringifyUrlQuery(
        {
          o: orgId,
        },
      )}`,
      payload,
      {
        loaderEnabled: showLoader,
      } as AxiosRequestConfig,
    )
    .then(({ data }) => data)
    .catch(standardizeErrorResponse);
}

export function getActions(
  orgId: string | number,
  type: ActionType,
  showLoader = true,
): Promise<IntegrationAction[]> {
  return httpClient
    .get<PaginatedResponse<IntegrationAction, CursorPaginationMetadata>>(
      `${INTEGRATION_ACTION_ENDPOINT}?${stringifyUrlQuery({
        o: orgId,
        type,
        latest: true,
      })}`,
      {
        loaderEnabled: showLoader,
      } as AxiosRequestConfig,
    )
    .then(({ data }) => data.data)
    .catch(standardizeErrorResponse);
}

export function postConnection(
  orgId: string | number,
  actionId: string,
  name: string,
  connection: UnknownRecord,
): Promise<string> {
  const body: ActionConnectionRequestBody = {
    action_id: actionId,
    connection,
    name,
  };

  return httpClient
    .post(
      `${INTEGRATION_CONNECTION_ENDPOINT}?${stringifyUrlQuery({
        o: orgId,
      })}`,
      body,
    )
    .then(({ data }) => data.id)
    .catch(standardizeErrorResponse);
}

export function postConfiguration(
  orgId: string | number,
  connectionId: string,
  name: string,
  input: UnknownRecord,
): Promise<string> {
  const body: ActionConfigurationRequestBody = {
    connection_id: connectionId,
    name,
    input,
  };

  return httpClient
    .post(
      `${INTEGRATION_CONFIGURATION_ENDPOINT}?${stringifyUrlQuery({
        o: orgId,
      })}`,
      body,
    )
    .then(({ data }) => data.id)
    .catch(standardizeErrorResponse);
}

export function postExecution(
  orgId: string | number,
  configurationId?: string,
): Promise<string> {
  const body: ActionExecutionRequestBody = {
    configuration_id: configurationId,
  };

  return httpClient
    .post(
      `${INTEGRATION_EXECUTION_ENDPOINT}?${stringifyUrlQuery({
        o: orgId,
      })}`,
      body,
    )
    .then(({ data }) => data.id)
    .catch(standardizeErrorResponse);
}

export function getExecutions(
  orgId: string | number,
  type: string | undefined = undefined,
  showLoader = true,
): Promise<ActionExecution[]> {
  return httpClient
    .get<ActionExecution[]>(
      `${INTEGRATION_EXECUTION_ENDPOINT}?${stringifyUrlQuery({
        o: orgId,
        type,
      })}`,
      {
        loaderEnabled: showLoader,
      } as AxiosRequestConfig,
    )
    .then(({ data }) => data)
    .catch(standardizeErrorResponse);
}

export function getExecution(
  id: string,
  orgId: string | number,
  showLoader = false,
): Promise<ActionExecution> {
  return httpClient
    .get<ActionExecution>(
      `${INTEGRATION_EXECUTION_ENDPOINT}/${id}?${stringifyUrlQuery({
        o: orgId,
      })}`,
      {
        loaderEnabled: showLoader,
      } as AxiosRequestConfig,
    )
    .then(({ data }) => data)
    .catch(standardizeErrorResponse);
}

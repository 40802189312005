// TODO: Consider using router.go(-1)

import VueRouter from 'vue-router';

export function goBack(router: VueRouter, fallbackRouteName: string) {
  const backRoute = router.currentRoute?.query?.frompage as string | undefined;
  if (backRoute) {
    router.push(backRoute);
  } else {
    router.push({ name: fallbackRouteName });
  }
}

import { makeQueryable } from '@ax/cache-and-dedupe-vue';
import { success } from '@ax/type-utils';
import { computed, Ref } from '@vue/composition-api';
import {
  defaultCatchHandler,
  QueryByOrgId,
} from '@ax/data-services-common/queries';
import { OSes } from '@ax/data-services-devices/models/oses';
import { OrgId } from '@/models/org-id';
import httpClient from './http-client';

const OSES_ENDPOINT = '/oses';
const OS_VERSIONS_ENDPOINT = '/os-versions';

/**
 * `GetOsesByOrdId` requires params of `{ readonly orgId: OrgId }`,
 * can succeed with type `OSes` and can fail with type `Axios Error`.
 * It only describes the requirements for a request to get `OSes` and how it
 * can succeed or fail.
 */
export class GetOSesByOrgId extends QueryByOrgId<OSes> {
  type = 'GetOrgOSesById';
}

const { useQuery: useOSesQuery } = makeQueryable(
  (query: GetOSesByOrgId) => {
    return httpClient
      .get<OSes | []>(`${OSES_ENDPOINT}?o=${query.orgId}&internal=1`, {
        loaderEnabled: query.showLoader,
      })
      .then(({ data }) => success(Array.isArray(data) ? {} : data))
      .catch(defaultCatchHandler);
  },
  {
    /**
     * As of 03/09/2022, the P95 response time for this API call is 895 ms. 10_000
     * is a very conservative timeout
     */
    timeout: 10_000,
  },
);

/**
 * Returns an object with reactive helpers depicting the current state of
 * the `GetOsesByOrgId` query for the given `orgId`. The reactive helpers include things
 * like `isLoading`, `data`, `hasErrors`, `isRefreshing`, etc. See
 * `@ax/cache-and-dedupe-vue/make-reactive-query-response.ts` for more info.
 * @param orgId `Ref<OrgId>`
 * @returns `ReactiveQueryResponse<GetOsesByOrgId>`
 */
export function useOrgOSesQuery(orgId: Ref<OrgId>) {
  return useOSesQuery(
    computed(() => new GetOSesByOrgId({ orgId: Number(orgId.value) })),
  );
}

export const getOsVersions = (orgId: OrgId): Promise<OSes> =>
  httpClient
    .get(`${OS_VERSIONS_ENDPOINT}?o=${orgId}&internal=1`)
    .then(({ data }) => {
      return Array.isArray(data) && data.length < 1 ? {} : data;
    });

import {
  DeviceBaseDTO,
  DeviceSoftwareDTO,
  DeviceStatus,
} from '@ax/data-services-devices/models';
import { Copyable } from '@ax/object-utils';
import {
  formatDateTimeDistance,
  formatUTCToZonedTime,
} from '@ax/date-time-utils';
import { Policy } from '@/models/policy';

export { DeviceSoftwareDTO as DeviceSoftware };

export enum CommandTypeName {
  getOS = 'GetOS',
  installAllUpdates = 'InstallAllUpdates',
  installUpdate = 'InstallUpdate',
  uninstallSoftware = 'UninstallSoftware',
  reboot = 'Reboot',
  deregister = 'Deregister',
}

class LastUserLogon {
  TIME?: string;

  USER?: string;

  SRC?: string;
}

class Volume {
  AVAIL?: string;

  LABEL?: string;

  FSTYPE?: string;

  VOLUME?: string;

  IS_SYSTEM_DISK?: string;

  FREE?: string;
}

class AutoUpdateOptions {
  ENABLED?: string;

  OPTIONS?: string;
}

export class WsusConfig {
  WSUS_SERVER?: string;

  WSUS_MANAGED?: string;

  WSUS_REACHABLE?: string;
}

class Disk {
  SIZE?: string;

  TYPE?: string;
}

export class UpdateSourceCheck {
  ERROR?: string;

  CONNECTED?: string;
}

class Nic {
  TYPE?: string;

  DEVICE?: string;

  VENDOR?: string;

  CONNECTED?: boolean;

  IPS?: string[];

  MAC?: string;
}

export class DeviceDetails {
  LAST_USER_LOGON?: LastUserLogon;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  SERVICETAG?: any;

  RAM?: string;

  MODEL?: string;

  VOLUME?: Volume[];

  AUTO_UPDATE_OPTIONS?: AutoUpdateOptions;

  SERIAL?: string;

  VENDOR?: string;

  DISKS?: Disk[];

  WSUS_CONFIG?: WsusConfig;

  PS_VERSION?: string;

  WMI_INTEGRITY_CHECK?: string;

  FQDNS?: string[];

  UPDATE_SOURCE_CHECK?: UpdateSourceCheck;

  NICS?: Nic[];

  VERSION?: string;

  CPU?: string;

  constructor(init: Partial<DeviceDetails>) {
    Object.assign(this, init);
  }
}

export class DevicePolicyStatus {
  id?: string;

  organization_id?: string;

  policy_id?: number;

  server_id?: string;

  policy_name?: string;

  policy_type_name?: string;

  status?: number;

  result?: object;

  create_time?: string;

  next_remediation?: string;

  constructor(init: Partial<DevicePolicyStatus>) {
    Object.assign(this, init);
  }
}

/* This class is shared between `/servers/{id}` and `/servers/{id}/queues` */
export class DeviceCommand {
  agent_command_type?: number;

  args?: string;

  command_id?: number;

  command_type_name?: CommandTypeName | string;

  exec_time?: string;

  id?: number;

  organization_id?: number;

  policy_id?: number;

  reboot?: number;

  response?: string | object;

  response_time?: string;

  server_id?: string | number;

  /* Properties below are available only in `/servers/{id}` */
  expires?: string;

  responsetime_cmd_policy_args_hash?: string;

  constructor(init: Partial<DeviceCommand>) {
    Object.assign(this, init);
  }
}

export interface DeviceDTO extends DeviceBaseDTO {
  commands?: DeviceCommand[];

  detail?: DeviceDetails;

  compatibility_checks?: DeviceCompatibility;

  needs_attention?: boolean;

  policy_status?: DevicePolicyStatus[];

  server_policies?: Policy[];

  status?: DeviceStatus;
}

export class Device extends Copyable<DeviceDTO> {
  /**
   * The `last_disconnect_time` formatted into a string
   */
  readonly last_disconnect_time_formatted = formatUTCToZonedTime(
    this.last_disconnect_time,
  );

  /**
   * The time since the `last_disconnect_time` formatted into relative time
   * string (e.g. 14 Days Ago)
   */
  readonly disconnected_for = formatDateTimeDistance(
    this.last_disconnect_time,
    new Date(),
    { unit: 'day' },
  );

  /**
   * A helper to map a `DeviceDTO` into a `Device` without using `new`
   * @param dto `DeviceDTO`
   * @returns `Device`
   */
  static fromDTO = (dto: DeviceDTO) => new Device(dto);
}

/**
 * Represents the raw data transfer object from the API
 */
export interface DevicesResponseDTO {
  /**
   * Number of devices that meet the query criteria
   */
  readonly size: number;
  /**
   * The paginated list of `DeviceDTO`s for the current query
   */
  readonly results: DeviceDTO[];
}
export class DevicesResponse extends Copyable<{
  /**
   * The total number of `Device`s that meet the query criteria. This
   * response does not use the `GlobalPagination` response format
   */
  readonly size: number;

  /**
   * The paginated list of `Device` objects for the current query.
   *
   * We want `Device[]` here vs. `DeviceDTO` because we calculate extra
   * fields on `Device` that are helpful in application code.
   */
  readonly results: Device[];
}> {
  /**
   * A helper to map a `DeviceResponseDTO` into a `DeviceResponse`
   * @param dto `DeviceResponseDTO`
   * @returns `DeviceResponse`
   */
  static fromDTO = (dto: DevicesResponseDTO) =>
    new DevicesResponse({
      size: dto.size,
      results: dto.results.map(Device.fromDTO),
    });
}

export interface DeviceCompatibility {
  low_diskspace?: boolean;
  missing_secure_token?: boolean;
  app_store_disconnected?: boolean;
  missing_powershell?: boolean;
  missing_wmi_integrity_check?: boolean;
  wsus_disconnected?: boolean;
  windows_update_server_disconnected?: boolean;
}

import { DeviceGroup } from '@/models/device-group';

export class PolicyDeviceFilterPreviewResponse {
  size: number;

  results: PolicyDeviceFilterPreview[];

  constructor(results: PolicyDeviceFilterPreview[], size: number) {
    this.size = size;
    this.results = results;
  }
}

export class PolicyDeviceFilterPreview {
  id?: number;

  uuid?: string;

  name?: string;

  custom_name?: string;

  os_version?: string;

  os_family?: string;

  server_group?: DeviceGroup[];

  tags?: string[];

  ip_addr?: string;

  ip_addrs_private?: string[];

  constructor(init: Partial<PolicyDeviceFilterPreview>) {
    Object.assign(this, init);
  }
}

export enum PolicyDeviceFilterField {
  tag = 'tag',
  hostname = 'hostname',
  ip_addr = 'ip_addr',
  os_family = 'os_family',
  os_version_id = 'os_version_id',
  organizational_unit = 'organizational_unit',
}

export enum PolicyDeviceFilterOperator {
  is = 'in',
  is_not = 'not_in',
  contains = 'like_any',
  does_not_contain = 'not_like_any',
}

export class PolicyDeviceFilter {
  field?: PolicyDeviceFilterField;

  op?: PolicyDeviceFilterOperator;

  value?: number[] | string[];

  constructor(init: Partial<PolicyDeviceFilter>) {
    Object.assign(this, init);
  }
}

export type InOperator = {
  text: 'Is' | 'Is Not';
  value: PolicyDeviceFilterOperator;
};

export type LikeOperator = {
  text: 'Contains' | 'Does Not Contain';
  value: PolicyDeviceFilterOperator;
};

export type Field = {
  text: string;
  value: PolicyDeviceFilterField;
};

export const InOperators: readonly [InOperator, InOperator] = [
  {
    text: 'Is',
    value: PolicyDeviceFilterOperator.is,
  },
  {
    text: 'Is Not',
    value: PolicyDeviceFilterOperator.is_not,
  },
] as const;

export const LikeOperators: readonly [LikeOperator, LikeOperator] = [
  {
    text: 'Contains',
    value: PolicyDeviceFilterOperator.contains,
  },
  {
    text: 'Does Not Contain',
    value: PolicyDeviceFilterOperator.does_not_contain,
  },
] as const;

export const LikeAndInOperators = [...InOperators, ...LikeOperators] as const;

export const FieldOptions: Field[] = [
  {
    text: 'Device Tag',
    value: PolicyDeviceFilterField.tag,
  },
  {
    text: 'IP Address',
    value: PolicyDeviceFilterField.ip_addr,
  },
  {
    text: 'Hostname',
    value: PolicyDeviceFilterField.hostname,
  },
  {
    text: 'OS',
    value: PolicyDeviceFilterField.os_family,
  },
  {
    text: 'OS Version',
    value: PolicyDeviceFilterField.os_version_id,
  },
  {
    text: 'Active Directory Organizational Unit',
    value: PolicyDeviceFilterField.organizational_unit,
  },
];

export const FieldToOperatorMap = {
  [PolicyDeviceFilterField.tag]: LikeAndInOperators,
  [PolicyDeviceFilterField.hostname]: LikeOperators,
  [PolicyDeviceFilterField.ip_addr]: LikeOperators,
  [PolicyDeviceFilterField.os_family]: InOperators,
  [PolicyDeviceFilterField.os_version_id]: InOperators,
  [PolicyDeviceFilterField.organizational_unit]: LikeAndInOperators,
};

import { Zone, ZoneRbacRole } from '@ax/api-clients-accounts/models';
import { AuthenticatorID } from './mfa';

export interface ConnectionZoneAssignment {
  id: Zone['id'];
  rbac_role: ZoneRbacRole;
}

export interface Connection {
  id: ConnectionUUID;
  acs_url?: string;
  ext_auth0_id: string;
  ext_auth0_name: string;
  name: string;
  provision_type: `${ProvisionType}`;
  signin_url?: string;
  signout_url?: string;
  metadata_url: string;
  base64_signing_cert?: string;
  entity_id: string;
  type: 'saml';
  domains?: { id: DomainUUID }[];
  zones?: ConnectionZoneAssignment[];
}

type ConnectionReadOnlyProperties =
  | 'id'
  | 'ext_auth0_id'
  | 'ext_auth0_name'
  | 'metadata_url';

export type ConnectionCreate = Omit<Connection, ConnectionReadOnlyProperties>;

export type ConnectionUpdate = Omit<Connection, ConnectionReadOnlyProperties>;

export interface ConnectionForm
  extends Omit<Connection, ConnectionReadOnlyProperties> {
  use_xml: boolean;
  xml_configuration?: string;
}

export interface Domain {
  id: DomainUUID;
  has_connection: boolean;
  name: string;
  verified: boolean;
  verification_txt: string;
}

export type DomainCreate = Omit<
  Domain,
  'id' | 'has_connection' | 'verified' | 'verification_txt'
>;

export interface DomainVerificationResponse {
  verified: boolean;
}

export enum ProvisionType {
  invite = 'invite',
  zone_rbac = 'zone_rbac',
}

type UUID = string;
export type ConnectionUUID = UUID;
export type DomainUUID = UUID;

export interface UserStorage {
  mfaNames?: { [key: AuthenticatorID]: string };
}

import { AxiosResponse, AxiosRequestConfig } from 'axios';
import { makeQueryable, ttl } from '@ax/cache-and-dedupe-vue';
import { prop } from '@ax/object-utils';
import { success } from '@ax/type-utils';

import {
  defaultCatchHandler,
  QueryByString,
} from '@ax/data-services-common/queries';
import { stringifyUrlQuery } from '@/utils/util';

import {
  Device,
  DevicesResponse,
  DeviceSoftware,
  DevicesResponseDTO,
  DeviceDTO,
} from '@/models/devices';
import { DeviceCommands } from '@/types/device-commands';

import httpClient from './http-client';

const DEVICES_ENDPOINT = '/servers';

const extractData = prop('data');

export class GetDevices extends QueryByString<DevicesResponse> {
  type = 'GetDevices';
}

// Devices

export const {
  useQuery: useGetDevicesQuery,
  runQuery: runGetDevices,
  cache: devicesCache,
} = makeQueryable(
  ({ query, showLoader: loaderEnabled }: GetDevices) =>
    httpClient
      .get<DevicesResponseDTO>(`${DEVICES_ENDPOINT}?${query}`, {
        loaderEnabled,
      })
      .then((response) => success(DevicesResponse.fromDTO(response.data)))
      .catch(defaultCatchHandler),
  {
    networkPolicy: ttl(60_000),
    /**
     * As of 03/21/2022, the P95 response time on `/api/servers` is 6.26 seconds.
     */
    timeout: 75_000,
  },
);

export function getDevices(query: string, showLoader?: boolean) {
  return runGetDevices(new GetDevices({ query, showLoader }));
}

export function clearDevicesCache() {
  devicesCache.clear();
}

export const removeDevice = (
  orgId: string | number,
  deviceId: string | number,
): Promise<AxiosResponse> => {
  const query = stringifyUrlQuery({ o: orgId, internal: 1 });

  return httpClient
    .delete(`${DEVICES_ENDPOINT}/${deviceId}?${query}`)
    .then(extractData);
};

// Device Queue/Actions
export const postDevicesBatchQueue = (
  deviceIds: string[] | number[],
  command: DeviceCommands,
  orgId: string | number,
  exec_time: string = new Date().toJSON(),
): Promise<AxiosResponse> =>
  httpClient
    .post(`${DEVICES_ENDPOINT}/batch/queues?o=${orgId}`, {
      batch: deviceIds,
      command_type_name: command,
      exec_time,
    })
    .then(extractData);

export const postDeviceQueue = (
  deviceId: string | number,
  command: DeviceCommands,
  orgId: string | number,
  exec_time?: string | null,
  args?: string | null,
): Promise<AxiosResponse> =>
  httpClient
    .post(`${DEVICES_ENDPOINT}/${deviceId}/queues?o=${orgId}&internal=1`, {
      command_type_name: command,
      server_id: deviceId,
      exec_time,
      args,
    })
    .then(extractData);

// Device Details
export const getDeviceDetails = (
  orgId: string | number,
  deviceId: string | number,
  showLoader = true,
): Promise<Device> => {
  const query = stringifyUrlQuery({
    o: orgId,
    internal: 1,
    commands: 1,
    _: new Date().getTime(),
  });

  return httpClient
    .get<DeviceDTO>(`${DEVICES_ENDPOINT}/${deviceId}?${query}`, {
      'Cache-Control': 'no-cache',
      loaderEnabled: showLoader,
    } as AxiosRequestConfig)
    .then(({ data }) => Device.fromDTO(data));
};

export const updateDeviceDetails = (
  orgId: string | number,
  deviceId: string | number,
  payload: Device,
): Promise<AxiosResponse> => {
  const query = stringifyUrlQuery({ o: orgId, internal: 1 });

  return httpClient
    .put(`${DEVICES_ENDPOINT}/${deviceId}?${query}`, payload)
    .then(extractData);
};

// Device Software
export async function getDeviceSoftware(
  orgId: string | number,
  deviceId: string | number,
  showLoader = true,
): Promise<DeviceSoftware[]> {
  const query = stringifyUrlQuery({ o: orgId, internal: 1 });
  const limit = 500;

  function makeRequest(page: number): Promise<DeviceSoftware[]> {
    const url = `${DEVICES_ENDPOINT}/${deviceId}/packages?${query}&limit=${limit}${
      page === 0 ? '' : `&page=${page}`
    }`;
    return httpClient
      .get(url, {
        loaderEnabled: showLoader,
      } as AxiosRequestConfig)
      .then(extractData);
  }

  const allDeviceSoftware: DeviceSoftware[][] = [];
  let stop = false;
  let page = 0;

  while (stop === false) {
    // eslint-disable-next-line no-await-in-loop
    const nextDeviceSoftware = await makeRequest(page);
    allDeviceSoftware.push(nextDeviceSoftware);
    if (nextDeviceSoftware.length >= limit) {
      page += 1;
    } else {
      stop = true;
    }
  }

  return allDeviceSoftware.flat();
}

export const updateDeviceSoftware = (
  orgId: string | number,
  deviceId: string | number,
  payload: DeviceSoftware,
): Promise<AxiosResponse> => {
  const query = stringifyUrlQuery({ o: orgId, internal: 1 });

  return httpClient
    .post(`${DEVICES_ENDPOINT}/${deviceId}/packages?${query}`, payload)
    .then((response) => response.data);
};

export const batchProcessTags = (
  action: 'apply' | 'remove',
  orgId: string | number,
  tags: string[],
  deviceIds: number[],
): Promise<AxiosResponse> => {
  const query = stringifyUrlQuery({ o: orgId, internal: 1 });
  const payload = {
    devices: deviceIds,
    actions: [{ action, attribute: 'tags', value: tags }],
  };

  return httpClient
    .post(`${DEVICES_ENDPOINT}/batch?${query}`, payload)
    .then(extractData);
};

/* To be used in the future */
export const batchRemoveTagsFromAllDevices = (
  orgId: string | number,
  tags: string[],
): Promise<AxiosResponse> => {
  const query = stringifyUrlQuery({ o: orgId, internal: 1 });
  const payload = {
    devices: 'all',
    actions: [{ action: 'remove', attribute: 'tags', value: tags }],
  };

  return httpClient
    .post(`${DEVICES_ENDPOINT}/batch?${query}`, payload)
    .then(extractData);
};

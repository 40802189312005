import { GlobalPaginationResponse } from '@ax/api-clients-common/models';
import { DoughnutSimpleSegment } from '@ax/cosmos/components/Charts';
import { Copyable } from '@ax/object-utils';
import { RawLocation } from 'vue-router';

/**
 * Interfaces and types for policy results data returned from the history service.
 */

export type PolicyStatistic = 'compliant' | 'failed' | 'pending' | 'success';

/**
 * The policy run results broken down by type.
 */
export type PolicyStatistics = Record<PolicyStatistic, number>;

export type PolicyType = 'patch' | 'custom' | 'required_software';
export type PolicyRunResultOptions = PolicyStatistic | 'not_run';
export type PolicyRunReasonOptions = 'offline' | 'needs_reboot' | string;

/**
 * The expected format of the individual array elements from the response
 * from the GET 'zones/{zoneId}/reports/policies` endpoint.
 *
 * @interface PolicyResults
 *
 * @member execution_time The time at which the policy started to execute.
 * @member id The id of the policy.
 * @member name The human-readable name of the policy.
 * @member policy_id The id of the policy.
 * @member statistics The results broken down by type (e.g. 'success', 'failed').
 * @member total_results The total number of run results of the policy.
 * @member type The type of policy.
 * @member uuid The uuid of the policy.
 * @member zone_id The uuid of zone associated with the policies.
 */
export interface PolicyResultsDTO {
  execution_time: string; // 2021-11-14T06:00:45.684Z
  id: number; // coming soon, was policy_id
  name: string;
  statistics: PolicyStatistics;
  total_results: number;
  total_success: number;
  type: PolicyType;
  uuid: string;
  zone_id: string;
}

export type PolicyResultsListDTO = GlobalPaginationResponse<PolicyResultsDTO>;

export class PolicyResults extends Copyable<PolicyResultsDTO> {
  readonly execution_time_date = new Date(this.execution_time);

  readonly timestamp = new Date();

  static fromDTO = (dto: PolicyResultsDTO) => new PolicyResults(dto);
}

export class PolicyResultsList extends Copyable<
  GlobalPaginationResponse<PolicyResults>
> {
  readonly timestamp = new Date();

  static fromDTO = (dto: PolicyResultsListDTO) =>
    new PolicyResultsList({
      metadata: dto.metadata,
      data: dto.data.map(PolicyResults.fromDTO),
    });
}

export /**
 * The expected format of the individual array elements from the response
 * from the GET `zones/{zoneId}/reports/policies/{policyId}/devices` endpoint.
 *
 * @interface PolicyResultsDevice
 *
 * @member device_id The id of the device.
 * @member device_name The human-readable name of the device.
 * @member device_uuid The uuid of the device.
 * @member execution_time When the policy run for the device "finished".
 * @member policy_id The id of the policy ran on the device.
 * @member policy_uuid The uuid of the policy ran on the device.
 * @member result The result of the policy run.
 * @member zone_id The uuid of the zone associated with the device.
 *
 * @member reason Optional. The reason why the policy was unable to run successfully.
 */
interface PolicyResultsDeviceDTO {
  device_id: number; // currently not actually being returned, but need it (GNR-40)
  device_name: string;
  device_uuid: string;
  execution_time: string; // 2021-11-14T06:00:45.684Z
  policy_id: number;
  policy_uuid: string;
  result: PolicyRunResultOptions;
  reason?: PolicyRunReasonOptions;
  zone_id: string;
}

export type PolicyResultsDevicesDTO =
  GlobalPaginationResponse<PolicyResultsDeviceDTO>;

export class PolicyResultsDevices extends Copyable<
  GlobalPaginationResponse<PolicyResultsDeviceDTO>
> {
  readonly timestamp = new Date();

  static fromDTO = (dto: PolicyResultsDevicesDTO) =>
    new PolicyResultsDevices(dto);
}

export interface PolicyResultsDoughnutData {
  chartData: DoughnutSimpleSegment[];
  legendData: Partial<Record<PolicyStatistic, RawLocation>>;
}

import { AxiosResponse } from 'axios';

import { AccountPrefs } from '@/models/account-pref';
import httpClient from './http-client';

export const getAccountPrefs = (accountId: string): Promise<AccountPrefs> => {
  return httpClient
    .get<AccountPrefs>(`/accounts/${accountId}/prefs?internal=1`)
    .then((response) => response.data);
};

export const postAccountPrefs = (
  accountId: string,
  payload: AccountPrefs,
): Promise<AxiosResponse> => {
  return httpClient.post(`/accounts/${accountId}/prefs?internal=1`, payload);
};
